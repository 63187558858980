import React from 'react';

import { Link } from 'react-scroll';

const Hero = () => {
  return (
    <section
      id='home'
      className='flex items-center lg:bg-contain lg:bg-[right_10rem_bottom_-4rem] lg:bg-no-repeat py-10 lg:py-32 lg:py-0 overflow-hidden'
    >
      <div className='container mx-auto'>
        <div className='flex flex-wrap items-center lg:pt-20'>
          <div className='w-full lg:w-1/2 flex flex-col items-center lg:items-start pb-10 lg:pb-0'>
            <h1 className='text-secondary text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px] text-center'>
            Vasyl Vepryk
            </h1>
            <p className='pt-4 pb-8 md:pt-6 md:pb-4 lg:max-w-[480px] text-lg text-center lg:text-left text-tertiary'>
            Nabízím zednické práce v okrese Ústí nad Orlicí společně s několika dalšími živnostníky, se kterými spolupracuji. Nabízíme veškeré výkopové a dokončovací stavební práce.   
            </p>
            <p className='pt-4 pb-2 md:pt-2 md:pb-4 lg:max-w-[480px] text-lg text-center lg:text-left text-tertiary'>
            <strong>Adresa:</strong> Rybník 10, Česká Třebová<br/><strong>IČO:</strong> 17412447
</p>
            <Link
                to={"contact"}
                activeClass='active'
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
              >
            <button className='btn btn-md bg-secondary hover:bg-secondary-hover md:btn-lg transition-all'>
              Kontaktujte nás
            </button>
            </Link>
          </div>
          <div className='w-full lg:w-1/2 flex flex-col items-center lg:items-start'>
          <img
            className='object-cover w-[566px] md:mx-auto lg:mx-0 rounded-2xl'
            src={'/img-2.jpg'}
            alt=''
          />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
