import React from 'react';
import TagManager from 'react-gtm-module'

// import components
import HeaderContainer from './components/HeaderContainer';
import Contact from './components/Contact';
import Footer from './components/Footer';
import BackTopBtn from './components/BackTopBtn';

const tagManagerArgs = {
  gtmId: 'GTM-NB6FQJT'
}

TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <div className='bg-primary relative'>
      <HeaderContainer />
      <Contact />
      <Footer />
      <BackTopBtn />
    </div>
  );
};

export default App;
