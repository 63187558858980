import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';


const Contact = () => {

  const form = useRef();
  const [emailSuccess, setEmailSuccess] = useState(0)

  const sendEmail = (e) => {
    e.preventDefault();

    if (!form.current[0].value || !form.current[1].value || !form.current[2].value) {
      setEmailSuccess(3)
      setTimeout(() => {
        setEmailSuccess(0)
      }, 5000);
      return
    }

    setEmailSuccess(4)

    emailjs.sendForm('service_cba64lh', 'template_10lky0n', form.current, 'Xqmjm8-5wAn2_ccDd')
      .then((result) => {
          //console.log(result.text);
          form.current.reset();
          
          window.dataLayer.push({
            event: 'vk-odeslano'
          });

          setEmailSuccess(1)
          setTimeout(() => {
            setEmailSuccess(0)
          }, 5000);
      }, (error) => {
          //console.log(error.text);
          setEmailSuccess(2)
          setTimeout(() => {
            setEmailSuccess(0)
          }, 5000);
      });

  };

  return (
    <section className='section pt-0 lg:pt-16 pb-10 lg:pb-16 bg-primary' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col items-center text-center'>
          <h2 className='text-secondary section-title before:content-contact relative before:absolute before:opacity-40 before:-top-7 before:-left-40 before:hidden before:lg:block'>
            Napište mi
          </h2>
          <p className='subtitle mb-10 lg:mb-10 text-tertiary'>
            V případě zájmu nás kontaktujte. Rádi si vyslechneme Vaše představy a
            navrhneme řešení přímo Vaším potřebám.
          </p>
        </div>
        <div className='flex flex-col lg:gap-x-8 lg:flex-row justify-center'>
          {/*
          <div
            className='flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2'
          >
            {contact.map((item, index) => {
              const { icon, title, subtitle, description } = item;
              return (
                <div className='flex flex-col lg:flex-row gap-x-4' key={index}>
                  <div className='text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl'>
                    {icon}
                  </div>
                  <div>
                    <h4 className='font-body text-xl mb-1'>{title}</h4>
                    <p className='mb-1 text-paragraph'>{subtitle}</p>
                    <p className='text-accent font-normal '>{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
          */}
          <form className='space-y-8 w-full max-w-[780px]' ref={form} onSubmit={sendEmail}>
            <div className='flex gap-8'>
              <input className='input' type='text' name="from_name" placeholder='Jméno' />
              <input className='input' type='email' name="from_email" placeholder='Email' />
            </div>
            <textarea
              className='textarea'
              placeholder='Zpráva'
              name='message'
            ></textarea>
            <button className='btn btn-lg bg-secondary hover:bg-secondary-hover tag-click-contact'>
              Odeslat zprávu
            </button>
          </form>

          </div>
          <div className='flex flex-col items-center text-left lg:mt-10'>

          {emailSuccess && emailSuccess === 1 ? (
          <p className='vk-green-text w-full max-w-[780px]'>
            Děkujeme Vám za zaslaný email. Ozveme se Vám.
          </p>) : ''}
          {emailSuccess && emailSuccess === 2 ? (
          <p className='vk-red-text w-full max-w-[780px]'>
            Bohužel došlo k chybě při odesílání. Zkuste to prosím později nebo nás případně můžete kontaktovat telefonicky na čísle 606 600 211.
          </p>) : ''}
          {emailSuccess && emailSuccess === 3 ? (
          <p className='vk-red-text w-full max-w-[780px]'>
            Pravděpodobně jste nevyplnily požadovaná pole.
          </p>) : ''}
          {emailSuccess && emailSuccess === 4 ? (
          <p className='vk-white-text w-full max-w-[780px]'>
            Probíhá odesílání emailu.
          </p>) : ''}

          </div>

      </div>
    </section>
  );
};

export default Contact;
